<template>
  <el-dialog :model-value="value" modal-class="custom-delete-dialog">
    <template #header>
      <span class="heading4">
        {{ $t('access_to_donatella') }}
      </span>
    </template>
    <div class="first-block">
      <span class="body2"
        >{{ $t('you_do_not_have_access_to_interact_with_donatella') }}
      </span>
    </div>
    <div class="second-block" style="margin-top: 0px">
      <span class="body2">
        {{
          $t(
            'register_to_have_access_to_our_ai_concierge_and_be_a_member_of_an_exclusive_community'
          )
        }}
      </span>
    </div>
    <div class="actions">
      <ElButton type="secondary dark" @click="$emit('close')">
        {{ $t('cancel') }}
      </ElButton>
      <ElButton type="primary dark" @click="register()">
        {{ $t('register') }}
      </ElButton>
    </div>
  </el-dialog>
</template>

<script>
export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    return {
      router,
      route
    }
  },
  methods: {
    register() {
      localStorage.setItem('temporaryRedirection', '/donatella')
      this.router.push('/auth/register')
      this.$emit('close')
    }
  }
})
</script>

<style>
</style>