<template>
  <el-dialog :model-value="value" modal-class="custom-delete-dialog">
    <template #header>
      <span class="heading4">
        {{ $t('proceed_with_booking') }}
      </span>
    </template>
    <div class="first-block">
      <span class="body2"
        >{{ $t('you_do_not_have_access_to_proceed_with_the_booking') }}
      </span>
    </div>
    <div class="second-block" style="margin-top: 0px">
      <span class="body2">
        {{
          $t(
            'register_to_book_with_us_and_be_a_member_of_an_exclusive_community'
          )
        }}
      </span>
    </div>
    <div class="actions">
      <ElButton type="secondary dark" @click="$emit('close')">
        {{ $t('cancel') }}
      </ElButton>
      <ElButton type="primary dark" @click="register()">
        {{ $t('register') }}
      </ElButton>
    </div>
  </el-dialog>
</template>

<script>
export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false
    },
    redirectionUrl: {
      type: String,
      default: ''
    }
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    return {
      router,
      route
    }
  },
  methods: {
    register() {
      localStorage.setItem('temporaryRedirection', this.redirectionUrl)
      this.router.push('/auth/register')
      this.$emit('close')
    }
  }
})
</script>

<style>
</style>